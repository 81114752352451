import React, { FC } from 'react';
import { Address } from 'viem';
import { useEnsAvatar, useEnsName } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { getExplorerURL } from '../utils';

export const WalletChip: FC<{ chainId: number, address: Address, linked?: boolean }> = ({ chainId, address, linked }) => {
  const { data: ensName } = useEnsName({ address, chainId: mainnet.id });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName ?? '', chainId: mainnet.id });
  
  return <span className="wallet-chip">
    { !linked && ensAvatar && <img src={ensAvatar} alt="" /> }
    { !linked && (ensName ?? address.slice(0, 6) + '...' + address.slice(-4)) }
    { linked && <a href={getExplorerURL(chainId, address)} target="_blank" rel="noopener noreferrer">
      { ensAvatar && <img src={ensAvatar} alt="" /> }
      { ensName ?? address.slice(0, 6) + '...' + address.slice(-4) }
    </a> }
  </span>;
};


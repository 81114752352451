import React, { FC } from 'react';
import { Address } from 'viem';
import { ContractMetadata } from '../types/ContractMetadata';
import { useTokenData } from '../hooks/useTokenData';
import { replaceURISchemas } from '../utils';
import { SaleConfigCard } from './SaleConfigCard';

export const Token: FC<{ chainId: number, address: Address, collectionMetadata?: ContractMetadata, tokenId: bigint, owner?: Address, minter?: Address }> = ({ chainId, address, collectionMetadata, tokenId, owner, minter }) => {
  const { tokenMetadata, saleConfigs, fetchSaleConfigs } = useTokenData(chainId, address, tokenId, minter);
  
  return <article className="collection__token token">
    <h2 className="token__title">{ tokenMetadata && tokenMetadata.name ? tokenMetadata.name : collectionMetadata?.name + ' #' + tokenId.toString()}</h2>
    <img className="token__artwork" src={replaceURISchemas(tokenMetadata?.image ?? '')} alt="" />
    <section className="token__actions">{owner && minter && saleConfigs && saleConfigs.map((saleConfig, index) => <SaleConfigCard key={saleConfig.name} chainId={chainId} collection={address} tokenId={tokenId} owner={owner} minter={minter} saleConfig={saleConfig} saleConfigIndex={BigInt(index)} fetchSaleConfigs={fetchSaleConfigs} />)}</section>
    <section className="token__description">
      <h3>Description</h3>
      <p>{ tokenMetadata && tokenMetadata.description ? tokenMetadata.description : collectionMetadata?.description }</p>
    </section>
  </article>;
};

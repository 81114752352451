import { useState } from "react";
import { Address, zeroAddress } from "viem";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useConfig } from "wagmi";
import { readContract, switchChain, waitForTransactionReceipt, writeContract } from "wagmi/actions";
import { StrategyConfig } from "../types/SaleConfig";

import iEazelMintStrategy from "../../artifacts/contracts/interfaces/common/IEazelMintStrategy.sol/IEazelMintStrategy.json";
import eazelERC1155Minter from "../../artifacts/contracts/EazelERC1155Minter.sol/EazelERC1155Minter.json";

export function useMint(chainId: number, collection: Address, tokenId: bigint, minter: Address, saleConfigIndex: bigint, strategy: StrategyConfig, qty: bigint, mintComplete: () => void) {
  const config = useConfig();
  const { address, chainId: activeChainId } = useAccount();
  const { openConnectModal } = useConnectModal();

  const [isMinting, setIsMinting] = useState(false);
  const [txHash, setTxHash] = useState<Address | undefined>();
  const [txError, setTxError] = useState<string | undefined>();
  const [txSuccess, setTxSuccess] = useState<Address | undefined>();

  const performMint = async () => {
    if (!address) {
      openConnectModal?.();
      return;
    }

    if (isMinting || txHash) {
      return;
    }
    setTxError(undefined);

    let hash: Address | undefined;
    try {
      setIsMinting(true);
      if (activeChainId !== chainId) {
        await switchChain(config, { chainId });
      }
      const value = await readContract(config, {
        abi: iEazelMintStrategy.abi,
        chainId,
        address: strategy.strategy,
        functionName: 'mintRequiredValue',
        args: [strategy.data, '0x', qty]
      });
      hash = await writeContract(config, {
        abi: eazelERC1155Minter.abi,
        chainId,
        address: minter,
        functionName: 'mint',
        args: [address, collection, tokenId, saleConfigIndex, strategy.strategy, '0x', qty, zeroAddress, zeroAddress, '0x'],
        value: value as bigint
      });
      setTxHash(hash);
    } catch {
      setIsMinting(false);
      return;
    }

    try {
      if (hash) {
        const receipt = await waitForTransactionReceipt(config, { hash, chainId });
        if (receipt.status === 'success') {
          setTxSuccess(receipt.transactionHash);
          mintComplete();
          setTimeout(() => {
            setTxSuccess(undefined);
          }, 5000);
        } else {
          setTxError('Transaction failed');
        }
      }
    } catch (error) {
      setTxError(error.message);
    } finally {
      setIsMinting(false);
      setTxHash(undefined);
    }
  };

  return { isMinting, txHash, txError, txSuccess, performMint };
}
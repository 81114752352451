import { Address } from "viem";
import { base, baseSepolia, mainnet } from "viem/chains";

export function replaceURISchemas(uri: string) {
  return uri.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/').replace('ipfs://', 'https://ipfs.io/ipfs/').replace('ar://', 'https://arweave.net/');
}

export function getChainName(chainId: number) {
  switch (chainId) {
    case mainnet.id: return mainnet.name;
    case base.id: return base.name;
    case baseSepolia.id: return baseSepolia.name;
    default: return 'Unknown';
  }
}

export function getExplorerURL(chainId: number, address: Address) {
  switch (chainId) {
    case mainnet.id: return `https://etherscan.io/address/${address}`;
    case base.id: return `https://basescan.org/address/${address}`;
    case baseSepolia.id: return `https://sepolia.basescan.org/address/${address}`;
    default: return '';
  }
}

export function getExplorerTxURL(chainId: number, txHash: string) {
  switch (chainId) {
    case mainnet.id: return `https://etherscan.io/tx/${txHash}`;
    case base.id: return `https://basescan.org/tx/${txHash}`;
    case baseSepolia.id: return `https://sepolia.basescan.org/tx/${txHash}`;
    default: return '';
  }
}

import { useConnectModal, useAccountModal } from '@rainbow-me/rainbowkit';
import React, { FC } from 'react';
import { useEnsAvatar, useEnsName } from 'wagmi';
import { useAccount } from 'wagmi';
import { mainnet } from 'wagmi/chains';

export const WalletButton: FC<{}> = () => {
  const { address } = useAccount();
  const { data: ensName } = useEnsName({ address, chainId: mainnet.id });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName ?? '', chainId: mainnet.id });
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  
  return <>
    { !address && <button className="connect-button" onClick={openConnectModal}>Connect</button> }
    { address && <button className="wallet-button" onClick={openAccountModal}>
      { ensName ?? address.slice(0, 6) + '...' + address.slice(-4) }
      { <img src={ensAvatar ?? 'eazel.jpg'} alt="" /> }
    </button> }
  </>;
};


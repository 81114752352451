import React, { FC } from 'react';
import { useConfig } from 'wagmi';
import { Token } from './Token';
import { replaceURISchemas } from '../utils';
import { useCollectionData } from '../hooks/useCollectionData';
import { useCollectionAddress } from '../hooks/useCollectionAddress';
import { WalletChip } from './WalletChip';

export const Collection: FC<{ chainId: number, collection?: string, tokenId?: bigint }> = ({ chainId, collection, tokenId }) => {
  const config = useConfig();
  const address = useCollectionAddress(config, collection);
  const { owner, metadata, tokenIds, minter } = useCollectionData(config, chainId, address, tokenId);

  return <section className="collection">
    { metadata && (metadata.name || metadata.image) && <header className="collection__header">
      { metadata.image && <img className="collection__image" src={replaceURISchemas(metadata.image)} alt="" /> }
      { metadata.name && <h2 className="collection__name">{metadata.name} { owner && <span className="collection__owner"> by <WalletChip chainId={chainId} address={owner} /></span> }</h2> }
    </header> }
    { address && tokenIds.map((id) => <Token key={id.toString()} chainId={chainId} collectionMetadata={metadata} address={address} tokenId={id} owner={owner} minter={minter} />) }
  </section>;
};

import React, { FC } from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Collection } from './components/Collection';
import { base, baseSepolia, mainnet } from 'viem/chains';

export const App: FC<{ chainId: number, ens?: string, collection?: string, tokenId?: bigint }> = ({ chainId, ens, collection, tokenId }) => {
  return <>
    <Header ens={ens} />
    <Collection chainId={chainId} collection={collection} tokenId={tokenId} />
    <Footer />
  </>;
};

import React, { FC, useEffect, useState } from 'react';
import { getEnsAvatar } from 'wagmi/actions';
import { normalize } from 'viem/ens';
import { mainnet } from 'viem/chains';
import { isAddress } from 'viem';
import { useConfig } from 'wagmi';
import { WalletButton } from './WalletButton';

const DEFAULT_AVATAR = 'eazel.jpg';

export const Header: FC<{ ens?: string }> = ({ ens }) => {
  const config = useConfig();
  const [avatar, setAvatar] = useState(DEFAULT_AVATAR);

  useEffect(() => {
    (async () => {
      if (!ens) {
        setAvatar(DEFAULT_AVATAR);
        return;
      }
      const avatarUrl = await getEnsAvatar(config, {
        assetGatewayUrls: { 
          ipfs: 'https://ipfs.io', 
        },
        name: normalize(ens),
        chainId: mainnet.id
      });
      if (avatarUrl !== null) {
        setAvatar(avatarUrl);
      } else {
        setAvatar(DEFAULT_AVATAR);
      }
    })();
  }, [ens]);

  return <header className="main__header">
    <img className="header__avatar" src={avatar} alt={avatar === DEFAULT_AVATAR ? 'Eazel' : ens} />
    <WalletButton />
  </header>;
};

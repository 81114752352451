import React, { FC } from "react";
import { Address } from "viem";
import { StrategyConfig } from "../types/SaleConfig";
import { getExplorerTxURL } from "../utils";
import { useMint } from "../hooks/useMint";

export const MintButton: FC<{ chainId: number, collection: Address, tokenId: bigint, minter: Address, saleConfigIndex: bigint, strategy: StrategyConfig, qty: bigint, mintComplete: () => void, maxMinted: boolean }> = ({ chainId, collection, tokenId, minter, saleConfigIndex, strategy, qty, mintComplete, maxMinted }) => {
  const { isMinting, txHash, txSuccess, txError, performMint } = useMint(chainId, collection, tokenId, minter, saleConfigIndex, strategy, qty, mintComplete);

  const showTx = () => {
    let url: string | undefined;
    if (txHash) {
      url = getExplorerTxURL(chainId, txHash);
    } else if (txSuccess) {
      url = getExplorerTxURL(chainId, txSuccess);
    }
    if (url) {
      window.open(url, '_blank');
    }
  };

  return <>
    <button className="mint-button" onClick={txHash || txSuccess ? showTx : performMint} disabled={(isMinting && !txHash) || maxMinted}>{isMinting ? (txHash ? 'Waiting transaction...' : 'Check your wallet...') : (txSuccess ? 'MINTED!' : (maxMinted ? 'MAX MINTED' : 'MINT'))}</button>
    {txError && <div className="mint-error">Error: {txError}</div>}
  </>;
};

import { readContract } from "wagmi/actions";
import { Address } from "viem";
import { useAccount, useConfig } from "wagmi";
import { useEffect, useState } from "react";

import eazelERC1155Minter from "../../artifacts/contracts/EazelERC1155Minter.sol/EazelERC1155Minter.json";

export function useMaxMintableTokens(chainId: number, collection: Address, tokenId: bigint, minter: Address, saleConfigIndex: bigint) {
  const config = useConfig();
  const { address } = useAccount();
  const [maxMintableTokens, setMaxMintableTokens] = useState<bigint | undefined>();

  const fetchMaxMintableTokens = () => {
    if (!address) {
      setMaxMintableTokens(undefined);
      return;
    }
    (async () => {
      try {
        const max = await readContract(config, {
          chainId,
          address: minter,
          abi: eazelERC1155Minter.abi,
          functionName: 'maxMintableByAccount',
          args: [address, collection, tokenId, saleConfigIndex]
        });
        setMaxMintableTokens(max as bigint);
      } catch (error) {
        setMaxMintableTokens(undefined);
      }
    })();
  }

  useEffect(() => {
    fetchMaxMintableTokens();
  }, [chainId, collection, tokenId, minter, saleConfigIndex, address, config]);

  return { maxMintableTokens, fetchMaxMintableTokens };
}

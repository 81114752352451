import './index.css';
import '@rainbow-me/rainbowkit/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { WalletProvider } from './WalletProvider';
import { isAddress } from 'viem';
import { base, baseSepolia, mainnet } from 'viem/chains';

const hostname = window.location.hostname;
const params = new URLSearchParams(window.location.search);

let chain = params.get('chain');
if (chain === null) {
  chain = 'mainnet';
}
let chainId: number = mainnet.id;
switch (chain) {
  case 'base':
    chainId = base.id;
    break;
  case 'base-sepolia':
    chainId = baseSepolia.id;
    break;
  default:
    chainId = mainnet.id;
}

let ens: string | undefined = undefined;
const ethIndex = hostname.indexOf('.eth');
if (ethIndex !== -1) {
  ens = hostname.substring(0, ethIndex + 4);
}

let collection = params.get('collection');
if (collection !== null && !isAddress(collection)) {
  collection = ens ?? null;
}

let tokenId = params.get('tokenid');
try {
  if (tokenId !== null && BigInt(tokenId) === undefined) {
    tokenId = null;
  }
} catch (e) {
  tokenId = null;
}

const root = createRoot(document.getElementsByTagName('main')[0]);
root.render(<WalletProvider chainId={chainId}>
  <App
    chainId={chainId}
    ens={ens}
    collection={collection !== null ? collection : undefined}
    tokenId={tokenId !== null ? BigInt(tokenId) : undefined}
  />
</WalletProvider>);

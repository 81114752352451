import { Address } from "viem";
import { useAccount, useConfig } from "wagmi";
import { readContract } from "wagmi/actions";

import eazelERC1155CollectionAbi from "../../artifacts/contracts/EazelERC1155Collection.sol/EazelERC1155Collection.json";
import { useEffect, useState } from "react";

export function useOwnedTokens(chainId: number, collection: Address, tokenId: bigint) {
  const config = useConfig();
  const { address } = useAccount();
  const [ownedTokens, setOwnedTokens] = useState<bigint>(0n);
  
  const fetchOwnedTokens = async () => {
    try {
      const data = await readContract(config, {
        chainId,
        address: collection,
        abi: eazelERC1155CollectionAbi.abi,
        functionName: 'balanceOf',
        args: [address, tokenId],
      });
      setOwnedTokens(data as bigint ?? 0n);
    } catch (error) {
      setOwnedTokens(0n);
    }
  };

  useEffect(() => {
    fetchOwnedTokens();
  }, [chainId, collection, tokenId, address]);

  return { ownedTokens, fetchOwnedTokens };
}
import React, { FC } from "react";

export const QuantitySelector: FC<{ max: bigint, value: bigint, onChange: (value: bigint) => void }> = ({ max, value, onChange }) => {
  const valueChanged = (newValue: string) => {
    var newBigInt = 1n;
    try {
      newBigInt = BigInt(newValue);
    } catch (e) { }
    if (newBigInt > max) {
      onChange(max);
    } else if (newBigInt < 1n) {
      onChange(1n);
    } else {
      onChange(newBigInt);
    }
  };

  const inputWidth = Math.min(5, 1 + value.toString().length);
  return <>
    <section className="sale-config__quantity-selector quantity-selector">
      <button className="quantity-selector__button" onClick={() => valueChanged((value - 1n).toString())} disabled={value === 1n}>-</button>
      <input className="quantity-selector__value" type="text" value={value.toString()} onChange={(e) => valueChanged(e.target.value)} style={{width: inputWidth + 'em'}} />
      <button className="quantity-selector__button" onClick={() => valueChanged((value + 1n).toString())} disabled={value === max}>+</button>
    </section>
  </>;
};

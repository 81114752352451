import { useConfig } from "wagmi";
import { Address } from "viem";
import { useEffect, useState } from "react";
import { readContract } from "wagmi/actions";
import { replaceURISchemas } from "../utils";
import { TokenMetadata } from "../types/TokenMetadata";
import { SaleConfig } from "../types/SaleConfig";

import eazelERC1155Collection from "../../artifacts/contracts/EazelERC1155Collection.sol/EazelERC1155Collection.json";
import eazelERC1155Minter from "../../artifacts/contracts/EazelERC1155Minter.sol/EazelERC1155Minter.json";

export function useTokenData(chainId: number, address: Address, tokenId: bigint, minter?: Address) {
  const [tokenMetadata, setTokenMetadata] = useState<TokenMetadata | undefined>();
  const [saleConfigs, setSaleConfigs] = useState<Array<SaleConfig> | undefined>();

  const config = useConfig();

  const fetchSaleConfigs = async () => {
    if (minter) {
      try {
        let configs = await readContract(config, { abi: eazelERC1155Minter.abi, address: minter, functionName: 'saleConfigs', args: [address, tokenId], chainId }) as Array<SaleConfig>;
        setSaleConfigs(configs as Array<SaleConfig>);
      } catch { }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let uri = await readContract(config, { abi: eazelERC1155Collection.abi, address, functionName: 'uri', args: [tokenId], chainId }) as string;
        if (URL.canParse(uri)) {
          uri = replaceURISchemas(uri);
          const uriResponse = await fetch(uri, { headers: { 'Accept': 'application/json' } });
          if (uriResponse.ok) {
            const uriJson = await uriResponse.json();
            setTokenMetadata(uriJson as TokenMetadata);
          }
        }
      } catch { }

      fetchSaleConfigs();
    })();
  }, [config, chainId, address, tokenId, minter]);

  return { tokenMetadata, saleConfigs, fetchSaleConfigs };
}

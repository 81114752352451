import { useEffect, useState } from "react";
import { getEnsAddress } from "wagmi/actions";
import { Config } from "wagmi";
import { Address, isAddress } from "viem";
import { mainnet } from "viem/chains";

export function useCollectionAddress(config: Config, collection?: string) {
  const [address, setAddress] = useState<Address | undefined>();

  useEffect(() => {
    if (!collection) return;
    if (isAddress(collection)) {
      setAddress(collection);
      return;
    }
    (async () => {
      const ensAddress = await getEnsAddress(config, {
        name: collection,
        chainId: mainnet.id
      });
      if (ensAddress !== null) {
        setAddress(ensAddress);
      } else {
        setAddress(undefined);
      }
    })();
  }, [collection]);

  return address;
}
import { useEffect, useState } from "react";
import { ContractMetadata } from "../types/ContractMetadata";
import { Config } from "wagmi";
import { Address } from "viem";
import { readContract } from "wagmi/actions";
import { replaceURISchemas } from "../utils";

import eazelERC1155Collection from "../../artifacts/contracts/EazelERC1155Collection.sol/EazelERC1155Collection.json";
import eazelERC1155TokenProvider from "../../artifacts/contracts/EazelERC1155TokenProvider.sol/EazelERC1155TokenProvider.json";

export function useCollectionData(config: Config, chainId?: number, address?: Address, tokenId?: bigint) {
  const [owner, setOwner] = useState<Address | undefined>();
  const [metadata, setMetadata] = useState<ContractMetadata>();
  const [tokenIds, setTokenIds] = useState<Array<bigint>>([]);
  const [minter, setMinter] = useState<Address | undefined>();

  useEffect(() => {
    if (!address) {
      setMetadata({});
      setTokenIds([]);
    } else {
      (async () => {
        try {
          let contractURI = await readContract(config, { abi: eazelERC1155Collection.abi, address, functionName: 'contractURI', chainId }) as string;
          if (URL.canParse(contractURI)) {
            contractURI = replaceURISchemas(contractURI);
            const contractURIResponse = await fetch(contractURI, { headers: { 'Accept': 'application/json' } });
            if (contractURIResponse.ok) {
              const contractURIJson = await contractURIResponse.json();
              setMetadata(contractURIJson as ContractMetadata);
            }
          }
        } catch { }

        try {
          let owner = await readContract(config, { abi: eazelERC1155Collection.abi, address, functionName: 'owner', chainId });
          setOwner(owner as Address);
        } catch { }

        try {
          let minter = await readContract(config, { abi: eazelERC1155Collection.abi, address, functionName: 'minter', chainId });
          setMinter(minter as Address);
        } catch { }

        if (tokenId !== undefined) {
          setTokenIds([tokenId]);
        } else {
          try {
            const tokenProvider = await readContract(config, { abi: eazelERC1155Collection.abi, address, functionName: 'tokenProvider', chainId }) as Address;
            const maxTokenId = await readContract(config, { abi: eazelERC1155TokenProvider.abi, address: tokenProvider, functionName: 'maxTokenId', args: [address], chainId }) as bigint;
            const tokenIds: bigint[] = [];
            for (let i = maxTokenId; i >= 0n; i--) {
              tokenIds.push(i);
            }
            setTokenIds(tokenIds);
          } catch { }
        }
      })();
    }
  }, [chainId, address, tokenId]);

  return { owner, metadata, tokenIds, minter };
}

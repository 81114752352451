import React, { FC, useState } from "react";
import { SaleConfig } from "../types/SaleConfig";
import { Address, formatEther } from "viem";
import { MintButton } from "./MintButton";
import { useOwnedTokens } from "../hooks/useOwnedTokens";
import { WalletChip } from "./WalletChip";
import { getChainName } from "../utils";
import { QuantitySelector } from "./QuantitySelector";
import { useMaxMintableTokens } from "../hooks/useMaxMintableTokens";
import { Price } from "./Price";

export const SaleConfigCard: FC<{ chainId: number, collection: Address, tokenId: bigint, owner: Address, minter: Address, saleConfig: SaleConfig, saleConfigIndex: bigint, fetchSaleConfigs: () => void }> = ({ chainId, collection, tokenId, owner, minter, saleConfig, saleConfigIndex, fetchSaleConfigs }) => {
  const { maxMintableTokens, fetchMaxMintableTokens } = useMaxMintableTokens(chainId, collection, tokenId, minter, saleConfigIndex);
  const { ownedTokens, fetchOwnedTokens } = useOwnedTokens(chainId, collection, tokenId);
  const [qty, setQty] = useState<bigint>(1n);

  const mintComplete = () => {
    setQty(1n);
    fetchSaleConfigs();
    fetchOwnedTokens();
    fetchMaxMintableTokens();
  };

  return <section className="sale-config__card">
    <h3 className="sale-config__title">{saleConfig.name}</h3>
    <section className="sale-config__actions">
      <Price value={saleConfig.value} qty={qty} />
      {maxMintableTokens !== undefined && maxMintableTokens > 1n && <QuantitySelector max={maxMintableTokens} value={qty} onChange={setQty} />}
      <section className="sale-config__mint-buttons">
        {saleConfig.strategies.map(strategy => <MintButton key={strategy.strategy} chainId={chainId} collection={collection} tokenId={tokenId} minter={minter} saleConfigIndex={saleConfigIndex} strategy={strategy} qty={qty} mintComplete={mintComplete} maxMinted={maxMintableTokens !== undefined && maxMintableTokens === 0n} />)}
      </section>
    </section>
    <section className="sale-config__info">
      {saleConfig.mintedTokens > 0 && <p className="sale-config-info__minted">{saleConfig.mintedTokens.toString()} minted</p>}
      {ownedTokens > 0 && <p className="sale-config-info__owned">You own {ownedTokens.toString()}</p>}
    </section>
    <section className="sale-config__details">
      <h4 className="sale-config-details__title">Details</h4>
      {owner !== saleConfig.recipient && <article className="sale-config-details__detail"><span className="sale-config-details__detail-label">Recipient</span> <span className="sale-config-details__detail-value"><WalletChip chainId={chainId} address={saleConfig.recipient} linked /></span></article>}
      <article className="sale-config-details__detail"><span className="sale-config-details__detail-label">Contract</span> <span className="sale-config-details__detail-value"><WalletChip chainId={chainId} address={collection} linked /></span></article>
      <article className="sale-config-details__detail"><span className="sale-config-details__detail-label">Chain</span> <span className="sale-config-details__detail-value">{getChainName(chainId)}</span></article>
    </section>
  </section>;
};
